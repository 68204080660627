.page-three {
  height: calc(var(--vh, 1vh) * 100);
  background: url("../../../assets/images/banner-03-kr.png");
  user-select: none;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  @media screen and (max-width: 840px) {
    background-size: cover;
  }

  .page-three-container {
    top: 0;
    position: absolute;
    width: 70%;
    color: #fff;
    text-align: left;
    height: 100%;
    position: relative;
    left: -3%;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0;
    z-index: 5;
  }
  &-content {
    display: flex;
    height: 100%;
    width: 100%;
    @media (max-width: 840px) {
      margin-top: 10vh;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 10%;
      left: 0;
      transition: all 0.2s !important;
      margin-top: 10vh;
      &:hover {
        margin-top: 4vh;
        ;
      }
    }
    .item {
      height: 100%;
      width: calc(100% / 4);
      transition: all 0.2s !important;
      padding: 0 30px;
      box-sizing: border-box;
      position: relative;
      @media screen and (max-width: 1140px) {
        padding: 0 15px;
      }
      @media (max-width: 576px) {

      }
      
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      @media (max-width: 840px) {
        width: 100%;
        height: 15%;
        padding: 20px;
        overflow: hidden;
      }

      &-content {
        margin-top: 30vh;
        @media (max-width: 840px) {
          margin-top: 0;
        }
        .title {
          width: 100%;
          margin-bottom: 5vh;
          word-break: keep-all;
          font-size: 30px;
          font-weight: 700;
          visibility: hidden;
          @media screen and (max-width: 1040px) {
            margin: 0;
            font-size: 20px;
            padding-bottom: 15px;
          }
        }

        .en {
          font-family: "Roboto", sans-serif;
          p {
            line-height: 1.18;
            font-family: "Roboto", sans-serif;
          }
          span {
            font-family: "Roboto", sans-serif;
          }
        }

        .ko {
          font-family: "Noto Sans KR";

          p {
            line-height: 1.37;
            font-family: "Noto Sans KR";
          }
          span {
            font-family: "Noto Sans KR";
          }
        }

        .desc {
          opacity: 0;
          font-size: 17px;
          line-height: 27px;

          font-weight: 100;
          @media (max-width: 1680px) {
            font-size: 14px;
          }
          @media (max-width: 1300px) {
            line-height: 22px;
          }
          @media screen and (max-width: 1140px) {
            font-size: 12px;
            line-height: 22px;
          }
          @media (max-width: 576px) {
            font-size: 10px;
            line-height: 16px;
            overflow-y: auto;
            filter: none !important;
          }
        }
      }
      &:hover {
        width: 40%;
        // background-color: rgba(77, 74, 73, 0.44);
        &::before {
          background-color: #fff;
          opacity: 0.1;
        }
        .desc {
          opacity: 1;
        }
        @media (max-width: 840px) {
          width: 100%;
          overflow: unset;
          @media(max-height: 900px){
            height: 30%;
          }
          @media(max-height: 700px){
            height: 40%;
          }

          &::before {
            background-color: unset;
            opacity: 0;
          }
        }
      }
    }
    .item + .item {
      border-left: 1px solid rgba(255, 255, 255, 0.32);
      @media screen and (max-width: 840px) {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.32);
      }
    }
  }
}
