.contact-container {
  height: 100vh;
  .contact-content {
    height: 100%;
    overflow-y: hidden;
    header {
      height: 18%;
      h1 {
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: 700;
        color: #000;
        position: relative;
        text-align: center;
        padding: 60px 0;
        user-select: none;
        cursor: text;
        visibility: hidden;
      }
      @media (max-width: 480px) {
        h1 {
          padding: 0;
          font-size: 24px;
        }
        transform: translateY(80px);
      }
    }
    .box-map {
      height: 82%;
      display: flex;
    }
    .map-child {
      width: 50%;

      iframe,
      .info {
        width: 100%;
        height: 100%;
      }
      .info {
        background: url(../../assets//images/side-menu-open.png);
        background-size: cover;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        min-height: 396px;
        justify-content: space-between;
        .en {
          font-family: "Roboto", sans-serif;
          p {
            line-height: 1.18;
            font-family: "Roboto", sans-serif;
          }
          span {
            font-family: "Roboto", sans-serif;
          }
        }

        .ko {
          font-family: "Noto Sans KR";

          p {
            line-height: 1.37;
            font-family: "Noto Sans KR";
          }
          span {
            font-family: "Noto Sans KR";
          }
        }
        .top {
          font-size: 16px;
          line-height: 1.6;
          color: white;
          padding: 12.8%;
          .content-mobile {
            display: none;
          }
          @media (max-width: 1311px) {
            padding-top: 18.8%;
            padding-left: 18.8%;
          }
          p {
            user-select: none;
            cursor: text;
            font-weight: 300;
            font-size: 14px;
          }
        }
        .bottom {
          padding: 12.8%;
          color: white;
          text-align: end;
          .title {
            user-select: none;
            cursor: text;
            font-size: 12px !important;
            font-weight: 100;
          }
          .hidden {
            width: 100%;
            height: 25px;
            background-color: transparent;
          }

          a {
            font-size: 14px;
            user-select: none;
            font-weight: 300;
          }
        }
      }
    }
    @media screen and (max-width: 676px) {
      .box-map {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .map-child {
          height: 50%;
          width: 100%;
          .intro {
            display: grid !important;
          }
        }

        .top {
          padding-top: 30px !important;
          text-align: center;
          padding-left: 10% !important;
          .content-mobile {
            display: block !important;
            font-weight: 300;
            font-size: 14px;
            padding-top: 20px;
          }
        }
        .bottom {
          display: none;
        }
      }
    }
  }
}
