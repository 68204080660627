@font-face {
  font-family: "Montserrat-Black";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Thin";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: bold;
  font-weight: 600;
  src: url("../fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/NotoSansCJKkr-Thin.otf");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/NotoSansCJKkr-Light.otf");
 // src: url("../fonts/NotoSansCJKkr-Light.woff");
 // src: url("../fonts/NotoSansCJKkr-Light.eot");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSansCJKkr-Regular.otf");
  src: url("../fonts/NotoSansCJKkr-Regular.woff");
  src: url("../fonts/NotoSansCJKkr-Regular.eot");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NotoSansCJKkr-Medium.otf");
 // src: url("../fonts/NotoSansCJKkr-Medium.woff");
 // src: url("../fonts/NotoSansCJKkr-Medium.eot");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NotoSansCJKkr-Bold.otf");
 // src: url("../fonts/NotoSansCJKkr-Bold.woff");
 // src: url("../fonts/NotoSansCJKkr-Bold.eot");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/NotoSansCJKkr-Black.otf");
  //src: url("../fonts/NotoSansCJKkr-Black.woff");
  //src: url("../fonts/NotoSansCJKkr-Black.eot");
}
