* {
  margin: 0;
  padding: 0;
  -webkit-filter: none !important;
  filter: none !important;
}

a {
  text-decoration: inherit;
  color: inherit;
}

ul {
  list-style-type: none;
}

$mainColor: #000000;
$subColor: #e7e7e7;

.wrapper_first {
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: opac;
  animation-name: opac;

  @-webkit-keyframes opac {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1 !important;
    }
  }

  @keyframes opac {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1 !important;
    }
  }

  .wrapper_ {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
  }

  .black_back {
    display: block;
    z-index: 5;
  }
}

.wrapper_page {
  width: 70%;
  height: 100%;
  z-index: 0;
  // background-color: lightgray;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  z-index: 5;
  transition: all 0.2s;
}

@mixin scroll-style {
  // overflow: -moz-scrollbars-none !important;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $mainColor;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: $subColor;
    background-color: #e7e7e7;
    border-radius: 0.5rem;
  }
}

@media (max-width: 768px) {
  .wrapper_page {
    width: 100% !important;
  }
}
