.page-fourth {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

.fourth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  @media (max-width: 484px) {
    left: 48%;
  }

  h1 {
    font-size: 98.5px;
    margin-bottom: 40px;
    text-align: center;
    visibility: hidden;
    font-weight: bold;
  }

  .list-history {
    visibility: hidden;
    .item-box {
      height: 250px;
      overflow-y: auto;
      color: #8b8b8b;
      padding-left: 30px;
      border-right: 0.5px solid #222;
      padding-right: 30px;
      margin-top: 24px;
      padding-top: 40px;
      padding-bottom: 40px;
      &:hover {
        &::-webkit-scrollbar {
          width: 1.5px;
        }

        &::-webkit-scrollbar-thumb {
          background: black;
        }
      }

      h2 {
        font-weight: 100;
        color: #000;
      }

      .en {
        font-family: "Roboto", sans-serif;
        p {
          line-height: 1.18;
          font-family: "Roboto", sans-serif;
        }
        span {
          font-family: "Roboto", sans-serif;
        }
      }

      .ko {
        font-family: "Noto Sans KR";

        p {
          line-height: 1.37;
          font-family: "Noto Sans KR";
        }
        span {
          font-family: "Noto Sans KR";
        }
      }

      .item-year {
        &:not(:first-child) {
          padding-top: 20px;
        }

        h2 {
          font-size: 13px;
          color: #222222;
          font-weight: 500;
          margin-bottom: 10px;
          line-height: 1.5;
        }

        > p {
          font-size: 13px;

          color: #222222;
          font-weight: 300;
          line-height: 1.6;
          > span {
            font-size: 13px;
            color: #222222;
            font-weight: 500;
            margin-bottom: 10px;
            line-height: 1.5;
          }
        }
      }
    }

    .item-history {
      text-align: left;
      padding-right: 15px;

      .year {
        p {
          font-size: 39.5px;
          padding-left: 30px;
          margin-bottom: 0;
          font-weight: bold;
          @media screen and (max-width: 1023px) {
            font-size: 20px;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 1px;
      }

      // ::-webkit-scrollbar-track {
      //     background: #cdcdcd;
      // }
    }
  }
}

@media screen and (max-width: 1023px) {
  .fourth-container {
    h1 {
      font-size: 30px;
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 500px) {
  .fourth-container {
    h1 {
      font-size: 25px;
      margin-bottom: 20px;
    }
    .item-box {
      margin-top: 0 !important;
      padding-top: 20px !important;
      border: none !important;
    }
  }
}
