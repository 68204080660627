html,
body {
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

body {
  overflow: auto;
  position: relative;
  height: 100%;
}

html {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.panel {
  height: 100vh;
  position: sticky;
  top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
body {
  font-family: "Roboto", sans-serif;
}

.menu_nav {
  position: fixed;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
  right: 100px;
  text-align: right;
}
@media screen and (max-width: 840px) {
  .menu_nav {
    right: 50px;
  }
}
.menu_nav button {
  position: relative;
  display: block;
  margin: 7px 0;
  transition: all 0.2s;
  right: 0;
  width: 7px;
  height: 7px;
  background-color: #fff;
  cursor: pointer;
  border-color: transparent !important;
  padding: 0;
  border-width: 0;
}
.menu_nav button::before {
  content: "";
  position: absolute;
  transition: all 0.5s;
  z-index: 0;
  top: 0;
  right: 0;
  width: 7px;
  height: 7px;
  background-color: #ff9933;
}
.menu_nav button:hover:before {
  width: 30px;
}

.menu_nav button.active {
  background-color: #ff9933;
}

.menu_nav .active:before {
  background-color: #ff9933;
  width: 25px;
}

@media screen and (max-width: 840px) {
  .menu_nav .active:before {
    width: 10px;
  }
  .menu_nav button:hover:before {
    width: 10px;
  }
}
#fp-nav {
  z-index: 1 !important;
  right: 100px !important;
  @media (max-width: 1024px) {
    right: 50px !important;
  }
  @media (max-width: 681px) {
    right: 40px !important;
  }
  ul {
    li {
      margin: 0 !important;
    }
  }
}
#fp-nav ul li a span {
  border-radius: 0 !important;
  position: relative;
  z-index: 1;
  height: 7px !important;
  width: 7px !important;
  border: 0;
  background: #222222;
  margin: 0 !important;
  transition: all 0.3s !important;
  left: unset !important;
  right: 0 !important;
}

#fp-nav ul li a.active span {
  background-color: #ff9933 !important;
  width: 25px !important;
  @media (max-width: 750px) {
    background-color: #ff9933;
    width: 10px !important;
  }
}
#fp-nav ul li a:hover span {
  width: 30px !important;
}

.note {
  color: #ff9933;
}
