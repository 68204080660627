.page-two {
  height: calc(var(--vh, 1vh) * 100);
  background: url("../../../assets/images/banner-02-kr.png");
  width: 100%;
  position: relative;
  user-select: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  .page-two-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    .page-two-container {
      max-width: 1324px;
      margin: 0 10%;

      @media (max-width: 687px) {

      }
    }
  }
  .brand_name_wr {
    position: absolute;
    top: 10%;
    left: 12.5%;
    font-size: 14px;
    line-height: 25px;
    .par_one {
      line-height: 24px;
      color: #fff;
      cursor: text;
      font-family: "Montserrat-Medium";
      padding: 0;
      margin: 0;
      visibility: hidden;
    }
  }
  .en {
    font-family: "Roboto", sans-serif;
    p {
      line-height: 1.18;
    }
  }

  .ko {
    font-family: "Noto Sans KR";

    p {
      line-height: 1.37;
      font-family: "Noto Sans KR";
    }
    span {
      font-family: "Noto Sans KR";
    }
  }
  .number{
    letter-spacing: 0;
  }
  .note {
    color: #ff9933;
  }
  .line-two {
    letter-spacing: -1.4px;
    @media (max-width: 687px) {
      letter-spacing: -0.1px;
    }
  }
  .line-three {
    letter-spacing: 1.9px;
  }
  .line-four {
    letter-spacing: 1px;
  }
  .line-five {
    letter-spacing: -2.7px;
    @media (max-width: 687px) {
      letter-spacing: -1px;
    }
  }
 
  .text_wr {
    max-width: 100%;
    word-break: break-all;
    font-size: 67px;
    visibility: hidden;
    .mo{
      display:block;
    }
    p span{
      //display: inline-block;
      word-break: break-all;
    }
    @media (max-width: 1400px) {
      font-size: 40px;
    }
    @media (max-width: 1100px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      font-size: 24px;
    }
    &.en {
      max-width: 1300px;
      @media (max-width: 600px) {
        width:250px;
        min-width: 250px;
        letter-spacing: 0px;
        text-align: justify;
        
        p{
         display:inline;
        }

        .other{
          padding: 0 6px;
        }
      }
    }
    &.ko {
      @media (max-width: 600px) {
        width:244px;
        min-width: 244px;

        text-align: justify;
        p{
         display:inline;
        }
        .other{
          padding: 0 6px;
        }
   
      }

      @media (max-width: 374.98px) {
        max-width: 240px;
      }
      .text-one {
        letter-spacing: 19.9px;
        @media (max-width: 1400px) {
          letter-spacing: 14.88px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 12px;
        }
        @media (max-width: 600px) {
          letter-spacing: 1px;
        }
      }
      .text-two {
        letter-spacing: 34.8px;
        @media (max-width: 1400px) {
          letter-spacing: 24.5px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 17.9px;
        }
        @media (max-width: 600px) {
          letter-spacing:1px;
        }
      }
      .text-three {
        letter-spacing: 9.5px;
        @media (max-width: 1400px) {
          letter-spacing: 7.85px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 7.1px;
        }
        @media (max-width: 600px) {
          letter-spacing: 1px;
        }
      }
      .text-four {
        letter-spacing: 1.1px;
        @media (max-width: 1400px) {
          letter-spacing: 2.6px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 3.9px;
        }
        @media (max-width: 600px) {
          letter-spacing: 1px;
        }
      }
    }

    &.en {
      p {
        //text-align: justify;
        font-size : 50px;
        @media (max-width: 1400px) {
          font-size: 30px;
        }
        @media (max-width: 1100px) {
          font-size: 20px;
        }
        @media (max-width: 600px) {
          font-size: 20px;
        }

      }
      .text-one {
        letter-spacing: 1px;
        @media (max-width: 1400px) {
          letter-spacing: 1px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 0.71px;
        }
        @media (max-width: 600px) {
          letter-spacing: 0px;
        }
      }
      .text-two {
        letter-spacing: -0.65px;
        @media (max-width: 1400px) {
          letter-spacing: -0.05px;
        }
        @media (max-width: 1100px) {
          letter-spacing: 0px;
        }
        @media (max-width: 600px) {
          letter-spacing: 0px;
        }
      }
      .text-three {
        letter-spacing: 1.45px;
        @media (max-width: 1400px) {
          letter-spacing: 1.3px;
        }
        @media (max-width: 1100px) {
        letter-spacing: 0.89px;
        }
        @media (max-width: 600px) {
          letter-spacing: 0px;
        }
      }
      .text-four {
        letter-spacing: 3.05px;
        @media (max-width: 1400px) {
          letter-spacing: 2.23px;
        }
        @media (max-width: 1100px) {
        letter-spacing: 1.52px;
        }
        @media (max-width: 600px) {
        letter-spacing: 0px;
        }
      }
      .text-five {
        letter-spacing: -0.25px;
        @media (max-width: 1400px) {
          letter-spacing: 0.2px;
        }
        @media (max-width: 1100px) {
        letter-spacing: 0.15px;
        }
        @media (max-width: 600px) {
        letter-spacing: 0px;
        }
      }
      .text-six {
        letter-spacing: 0px;
        @media (max-width: 1400px) {
          letter-spacing: 0px;
        }
        @media (max-width: 1100px) {
        letter-spacing: 0px;
        }
        @media (max-width: 600px) {
        letter-spacing: 0px;
        }
      }
    }
    p {
      white-space: pre-wrap;
      font-weight: 700;
    //  letter-spacing: 0.67px;
      word-break: break-all;
      transition: all 0.2s;
      color: #fff;
      cursor: text;
      padding: 0;
      margin: 0;
      @media (max-width: 687px) {
        .self {
          letter-spacing: 2.2px;
        }
        .device {
          letter-spacing: 0;
        }
        .line-two {
          letter-spacing: -1px;
        }
        .ervers {
          letter-spacing: -0.9px;
        }
        .device-2 {
          letter-spacing: -0.9px;
        }
        .tion {
          letter-spacing: 0px;
        }
      }
    }
  }

  .mo1{
    text-align: none;
    max-width: 100%;
    word-break: break-all;
    font-size: 24px;
    visibility: hidden;

    .mo{
      display:block;
        font-size: 24px;
      
    }
    &.en {
      max-width: 1300px;
        width:250px;
        min-width: 250px;
        letter-spacing: 0px;
    }
    &.ko {
        width:244px;
        min-width: 244px;

        max-width: 240px;
        text-align: unset;
      .text-one {
          letter-spacing: 1px;
      }
      .text-two {
          letter-spacing: 3.1px;
      }
      .text-three {
          letter-spacing: 1.2px;
      }
      .text-four {
          letter-spacing: 0.8px;
      }
      .text-five {
          letter-spacing: 0.4px;
      }
    }
    &.en {

      text-align: unset;
        
      p {
          font-size: 20px;

      }
      .text-one {
        letter-spacing: 0.27px;
      }
      .text-two {
        letter-spacing: 0px;
      }
      .text-three {
        letter-spacing: 0.18px;
      }
      .text-four {
        letter-spacing: 0px;
      }
      .text-five {
        letter-spacing: 0.19px;
      }
      .text-six {
        letter-spacing: 0.4px;
      }
      .text-seven{
        letter-spacing: 0.1px;
      }
      .text-eight{
        letter-spacing: 0px;
      }
      .text-nine{
        letter-spacing: 0.1px;
      }//
      .text-ten{
        letter-spacing: 0px;
      }
      .text-tenone{
        letter-spacing: 0px;
      }
    }
    p {
      white-space: pre-wrap;
      font-weight: 700;
    //  letter-spacing: 0.67px;
      word-break: break-all;
      transition: all 0.2s;
      color: #fff;
      cursor: text;
      padding: 0;
      margin: 0;
      @media (max-width: 687px) {
        .self {
          letter-spacing: 2.2px;
        }
        .device {
          letter-spacing: 0;
        }
        .line-two {
          letter-spacing: -1px;
        }
        .ervers {
          letter-spacing: -0.9px;
        }
        .device-2 {
          letter-spacing: -0.9px;
        }
        .tion {
          letter-spacing: 0px;
        }
      }
    }

  }
}
